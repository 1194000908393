import { FastField } from 'formik';
import React from 'react';

import styles from './checkbox.module.css';

export const CustomCheckbox = ({ name, id, children }) => {
  return (
    <label className={styles.label} htmlFor={id}>
      <FastField type="checkbox" name={name} id={id} className={styles.checkbox} />
      <div className={styles.state} />
      <div className={styles.labelText}>{children}</div>
    </label>
  )
};